/* universal class to pad up p tags and divide with lines */
.right-margin {
    margin-right: 80px;
}
.left-margin{
    margin-left: 80px;
}
.grey-line-unpadded {
    width: 100%;
    height: 2px;
    background-color: rgb(195, 195, 195);
}

/* start of base CSS, self explanatory */

.emporia-container{
    margin-left: 80px;
    margin-right: 80px;
}
.emporia-subheading{
    font-size: 3.3vw;
}

.emporia-subsection {
    display: flex;
    align-items: center;
    margin-top: 85px;
    margin-bottom: 85px;
}
.emporia-subsection-p{
    font-size: 2vw;
    color: rgb(75, 75, 75);
}
.emporia-subsection-img {
    width: 33vw;
    /* border: 10px solid black; -- Redacted. Border was part of screenshots! */
    border-radius: 20px;
}


@media (max-width: 650px) {
    /* null out universal classes */
    .left-margin{
        margin-left: 0;
    }
    .right-margin {
        margin-right: 0;
    }
    .emporia-container{
        margin-left: 10px;
        margin-right: 10px;
    }
    .emporia-subheading {
        width: 100%;
        text-align: center;
        font-size: 42px;
    }
    .emporia-subsection {
        flex-direction: column;
        gap: 40px;
    }
    .emporia-subsection-p {
        text-align: center;
        font-size: 24px;
    }
    .emporia-subsection-img {
        width: 77vw;
    }
    .reverse-in-mobile {
        flex-direction: column-reverse;
    }
}