/* .technology-con .banner {
    height: 750px;

} */

.tech-text-con {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 80px;
    padding-top: 5%;
}

.images-con {
    display: grid;
    grid-template-columns: repeat(4, minmax(200px, 1fr)); 
    justify-content: center;
    text-align: center;
    padding-bottom: 5%;
    row-gap: 30px;
    column-gap: 7.5%;
    padding-left: 4%;
}


@media (max-width: 1300px) {
    .images-con {
        grid-template-columns: repeat(2, minmax(200px, 1fr));
    }
}
  
.indv-image-con img {
    width: 300px;
    height: 250px;
    border-radius: 8px;
    object-fit: cover;
}
  
.indv-image-con h3 {
    margin-top: 20px;
    text-align: start !important;
    font-size: 25px;
    color: #333;
    max-width: 305px;
    padding-left: 5px;
}

.tech-h2 {
    font-size: 45px;
    padding-bottom: 1%;
    padding-left: 4%;

}

.tech-h1 {
    font-size: 45px;
    padding-bottom: 1%;
    padding-left: 4%;
}

.page-break {
    border: none; 
    border-top: 2px solid #ccc; 
    width: 95%; 
    padding-left: 4%;
    padding-top: 5%;
}

.tech-info {
    padding-left: 4%;
    padding-right: 3%;
    padding-bottom: 5%;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 0px;
    color: rgb(93, 93, 93);
    
}

.tech-main-image {
   filter: brightness(70%);
   height: 70%;
} 

  
.tech-team-con {
    width: 100%;
    margin-left: -30px
  }
  .profile-card {
    padding: 0;
    box-shadow: none !important;
    background-color: none !important;
  }
  
  
.tech-grid {
    display: grid;
        grid-template-columns: repeat(4, minmax(200px, 1fr));
        justify-content: space-evenly;
        
    text-align: center;
    /* padding-bottom: 5%; */
    row-gap: 30px;
    column-gap: 7.5%;
    padding-left: 4%;
    height: 100% !important;

    
}

@media (max-width: 1300px) {
    .tech-grid {
        grid-template-columns: repeat(2, minmax(200px, 1fr));
    }
}
  

.indv-image-con {
    text-decoration: none;
    padding: 0;
    margin: 0;
}

.tech-image {
    width: 305px;
    height: 250px;
    border-radius: 8px;
    object-fit: cover;
    padding: 0;
    margin: 0;
}

.person-text-con {
    display: flex;
    flex-direction: column;
    text-align: start;
    padding-top: 5%;
    gap: 20px;
    max-width: 300px;
    padding-left: 3px;
}


.title {
    padding: 0;
    margin: 0;
    color: rgb(93, 93, 93);
    font-size: 20px;
}

.tech-name {
    text-decoration: none; 
    color: inherit; 
    list-style: none;
    font-size: 18px;
    margin: 0;
}

.no-underline {
    text-decoration: none !important; 
    color: inherit;
    width: 100%;
}

.linked-in-con {
    display: flex;
    align-items: center;
    gap: 5px;
    color: rgb(93, 93, 93);
    font-size: 20px;
    margin: 0;
}

.linked-in-con p {
    margin: 0; 
}

.indv-tech-con {
    border-radius: 12px;
}

.indv-tech-con:hover {
    transform: scale(1.025); 
}

@media (max-width: 600px) {

    .tech-grid {
        height: 400vh;
    }

    .tech-h1 {
        text-align: center;
        font-size: 20px;
        padding-top: 25px;
    }

    .tech-info {
        text-align: justify;
        font-size: 18px;
        padding-top: 15px;
        padding-bottom: 40px;
    }

    .tech-h2 {
        font-size: 25px;
        text-align: center;
        padding-top: 40px;
    }

    .images-con {
        padding-top: 30px;
        display: grid;
        grid-template-columns: repeat(2, 1fr); 
        gap: 15px; 
        text-align: center;
        max-width: 100%;
        padding-bottom: 50px !important;
        padding: 20px;
        
        
    }
    .images-con img {
        width: 100%;
        height: 80%;
    }

    
    
    .profile-card {
        background-color: #ebebeb; 
        border-radius: 10px; 
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
        text-align: center; 
        padding: 15px; 
        transition: transform 0.3s ease; 
    }
    
    .profile-card:hover {
        transform: scale(1.05);
    }
    
    .profile-card img {
        width: 100%; 
        height: auto;
        border-radius: 8px; 
        object-fit: cover; 
    }
    
    .h3-title {
        margin-top: 15px; 
        font-size: 18px; 
        color: #333; 
        text-align: center !important;
    }

    .h3-title {
        padding: 5px;
    }

    .page-break {
        padding: 0;
        margin: auto;
    }

    .tech-grid {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        padding: 20px;
        text-align: center;
        height: auto;
    }
    
    .profile-card {
        background-color: #ebebeb;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        text-align: center;
        padding: 20px;
        transition: transform 0.3s ease;
    }
    
    .profile-card img {
        width: 100%;
        border-radius: 8px;
        object-fit: cover;
    }
    
    .profile-card h3 {
        margin-top: 15px;
        font-size: 18px;
        color: #333;
    }
    
    .person-text-con {
        display: flex;
        flex-direction: column;
        text-align: center;
        gap: 5px;
        padding-bottom: 10px;
    }
    
    .linked-in-con {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        color: rgb(93, 93, 93);
        font-size: 16px;
    }
    
    .linked-in-con p {
        margin: 0;
    }
    .tech-name {
        font-size: 15px;
    }
    

  }

