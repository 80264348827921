/* defining the root container css first, mainly margins */
.homepage-container{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 80px;
}

/* everything else is defined top down, outer to inner*/
.homepage-arrow {
    width: 65px;
    height: 50px;
    opacity: 0; /* Start transparent */
    transition: opacity 0.8s ease-in; /* Define the transition */
    display: block;
    margin: 0 auto;
    margin-top: 20px;
}
/* fade effect for the arrow*/
.homepage-arrow.fade-in {
    opacity: 1; 
}

.homepage-paragraph-container{
    margin-bottom: 80px;
    border-bottom: 3px solid lightgray;
    padding-bottom: 80px;
}
.homepage-header{
    font-size: 45px;
    margin-top: 70px;
}
.ourmission-p {
  margin-top: 30px;
}
.homepage-paragraph-container p{
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 0px;
    color: rgb(93, 93, 93);
}

.homepage-services-container {
    margin-bottom: 80px;
    border-bottom: 3px solid lightgray;
    padding-bottom: 80px;
}
/* services grid stuff unused right now but once services are built will be needed. commented out in component */
.services-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 20px; 
    width: 100%
}

.services-grid a {
  text-decoration: none;
}

.service-img {
    width: 100%;
}
.grid-item {
    height: 300px;
    min-width: 200px;
    background-color: #f0f0f0; 
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: #333;
    border: 1px solid #ccc; 
}

.homepage-publications-container {
  width: 100%;
  margin-top: 40px;
}

/* the publications box with background image and effects */

/* .homepage-publications-box {
  position: relative;
  width: 100%;
  height: 300px;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0 4px 3px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
}

.homepage-publications-box::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('/public/images/linkedin_thumbnail.png');
  background-size: cover;
  background-position: center;
  filter: blur(0px) ;
  transition: filter 0.5s ease;
  z-index: 1;
}

.homepage-publications-box p {
  position: absolute;
  text-shadow:
  0px 0px 4px rgba(0, 0, 0, 0.7),
  0px 0px 8px rgba(0, 0, 0, 0.5),
  0px 0px 12px rgba(0, 0, 0, 0.7),
  0px 0px 16px rgba(0, 0, 0, 0.3);


  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 32px;
  color: #02abc7;
  text-align: center;
  z-index: 2;
  transition: transform 0.5s ease;
  margin: 0;
  width: 50%;
}
.homepage-publications-tinted {
  width: 300px;
  height: 300px;
  background-color: #000711;
  position: absolute;
  top: 1;
  left: 1;
}

.homepage-publications-box:hover::before {
  filter: blur(4px) brightness(0.9);
}

.homepage-publications-box:hover p {
  transform: translate(-50%, -50%) scale(1.1);
} */
.homepage-linkedin-container {
  height: 300px;
  width: 100%;
  background-image: url('/public/images/linkedin_thumbnail.png');
  border-radius: 20px;
  transition: 0.5s;
}
.homepage-linkedin-inner {
  height: 100%;
  width: 100%;
  max-width: 400px;
  background-color: #000000a3;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.homepage-linkedin-inner p {
  color: white;
  font-size: 20px;
  text-shadow: rgb(0, 0, 0) 1px 0 10px;
  transition: 0.5s;
}
.homepage-linkedin-inner:hover p{
  color: #00d8fd;
  transition: 0.5s;
  font-size: 21.2px;
}


.no-underline {
  text-decoration: none;
}


.homepage-emporia-container {
  background: 
      linear-gradient(0deg, #2d4d57, #000711),
      radial-gradient(67.19% 103.94% at 0% 0%, rgba(100, 215, 235, 0.15) 0%, rgba(61, 165, 181, 0) 100%),
      radial-gradient(100% 154.7% at 100% 100%, rgba(114, 221, 240, 0.15) 0%, rgba(50, 146, 180, 0) 100%);
  height: auto;
  border-radius: 15px 15px 0 0;
  margin: 0 auto;
  transition: width 1s ease, border-radius 1s ease;
  width: 90%; /* Default width */
}
.homepage-emporia-container.expand-width {
  width: 100%; /* Expand to full width */
  border-radius: 0; /* Remove border radius for full-width effect */
}

.emporia-logo-con {
  text-align: center;
  display: flex;
  flex-direction: column;
  padding-top: 150px;
  width: 75%;
  margin: auto;
}

.emporia-main-logo {
  max-width: 425px;
  width: 100%;
  
  align-self: center;
}

.emporia-description {
  color: white;
  padding-top: 25px;
  font-size: 40px;
  
  display: flex;
  text-align: center;
  padding-bottom: 100px;
  margin:auto;
}

.what-is-emporia-con {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 75%;
  margin: auto;
}

.what-emporia {
  color: white;
  font-size: 40px;
  margin-top: 0;
}

.what-is-desc {
  color: white;
  padding-top: 25px;
  font-size: 30px;
  
  display: flex;
  align-items: center;
  padding-bottom: 100px;
}

.marketing-made-simple-con {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
  margin: auto;

}

.whites-boxes-con {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  padding-top: 50px;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-bottom: 100px;
}

.indv-white-con {
  background-color: white;
  border-radius: 12px;
  padding: 15px;
  height: 300px;
  width: 100%;
  max-width: 375px;
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: center; 
  text-align: center;
}

.text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 175px; 
}

.white-box-h1 {
  font-size: 30px;
  font-weight: 500;
  margin: 0px !important;
  padding-bottom: 20px;
}

.white-boxes-p {
  font-size: 20px;
  margin: 0; 
}


.page-break-home {
  border: none; 
    border-top: 2px solid #ccc; 
    width: 75%; 
    padding-left: 4%;
    padding-top: 5%;
}

.emporia-mac-logo {
  width: 50%;
  margin-bottom: 30px;
}

.click-to-see-con {
  background-color: #e9e6e6;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
  height: auto;
}

.click-btn {
  font-size: 40px;
  margin-bottom: 50px;
  font-weight: 500;
  display: flex;
  align-items:end;
  border: none;
  background-color: rgba(255, 255, 255, 0);
  transition: ease 0.5s;
  cursor: pointer;
}
.click-btn:hover {
  color:#487a89;
  transition: ease 0.5s;
}

.get-a-demo {
  background: 
    linear-gradient(180deg, #2d4d57, #000711),
    radial-gradient(67.19% 103.94% at 0% 100%, rgba(100, 215, 235, 0.15) 0%, rgba(61, 165, 181, 0) 100%),
    radial-gradient(100% 154.7% at 100% 0%, rgba(114, 221, 240, 0.15) 0%, rgba(50, 146, 180, 0) 100%);
  height: auto;
  border-radius: 0;
}

.get-a-demo {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 80px;
}

.get-a-h1 {
  margin-top: 0px;
  color: white;
  font-size: 40px;
  font-weight: 500;
  padding-bottom: 80px;
  text-align: center;
}

.get-a-demo-temp {
  color: black;
  font-size: 40px;
  font-weight: 500;
  padding-bottom: 30px;
}

.get-in-touch {
  background-color: white;
  width: 225px;
  height: 75px;
  border: none;
  border-radius: 12px;
  margin-bottom: 60px;
  font-size: 28px;
  font-weight: 500;
  transition: ease 0.5s;
}
.get-in-touch:hover {
  background-color: #848484;
  transition: 0.5s;
}




@media (max-width: 600px) {
  .homepage-arrow.fade-in {
    visibility: hidden;
  }

  .homepage-header {
    text-align: center;
    padding-bottom: 30px;
    font-size: 30px;
    margin: 0;
  }
  .homepage-paragraph-container p{
    font-size: 16px;
  }

  .ourmission-p {
    text-align: left;
    
    margin-top: 0;
  }
  .service-item {
    padding: 0 !important;
  }
  .service-img {
    /* width: 400px !important;  */
    height: 200px !important;
    object-fit: cover !important;
  }
  .homepage-service-item {
    text-align: center !important;
    font-size: 16px !important;
    
  }
  .homepage-linkedin-inner {
    width: 100%;
    max-width: none;
  }
}

/* Wrapper for the entire expanding content */
.homepage-wrapper {
  width: 100%; /* Default width */
  margin: 0 auto;
  transition: width 1s ease, border-radius 1s ease;
  border-radius: 15px; /* Default rounded corners */
}

.homepage-wrapper.expand-width {
  width: 100%; /* Expand to full width */
  border-radius: 0; /* Remove border radius when expanded */
}